import React, { useState, useEffect } from "react"
import "./PartnerForm.css"
import { Form, Button, FloatingLabel, Modal } from "react-bootstrap"
import Fade from "react-reveal/Fade"
// import DemoModal from "../DemoModal/DemoModal"

const PartnerForm = () => {
  // const [show, setShow] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [country, setCountry] = useState("")
  const [findUs, setFindUs] = useState("")
  const [itPartner, setItPartner] = useState("")
  const [wifiIot, setWifiIot] = useState("")
  const [cloud, setCloud] = useState("")
  const [message, setMessage] = useState("")
  const [sent, setSent] = useState("")

  const handleFirstName = e => {
    setFirstName(e.target.value)
  }
  const handleLastName = e => {
    setLastName(e.target.value)
  }
  const handleEmail = e => {
    setEmail(e.target.value)
  }
  const handleCompany = e => {
    setCompany(e.target.value)
  }
  const handleCountry = e => {
    setCountry(e.target.value)
  }
  const handleFindUs = e => {
    setFindUs(e.target.value)
  }
  const onChangeItPartner = e => {
    setItPartner(e.target.value)
  }
  const handleWifiIot = e => {
    setWifiIot(e.target.value)
  }
  const handleCloud = e => {
    setCloud(e.target.value)
  }
  const handleMessage = e => {
    setMessage(e.target.value)
  }
  const clearForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setCompany("")
    setCountry("")
    setFindUs("")
    setItPartner("")
    setWifiIot("")
    setCloud("")
    setMessage("")
    setSent("")
  }

  const submitHandler = e => {
    e.preventDefault()
    const userInputFormData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: company,
      country: country,
      findUs: findUs,
      itPartner: itPartner,
      wifiIot: wifiIot,
      cloud: cloud,
      message: message,
    }

    console.log(userInputFormData, "data")
    setSent("Sending message...")
    sendEmail()
    clearForm()
  }

  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  const sendEmail = () => {
    var myHeaders = new Headers()
    myHeaders.append("token", "MXSDE7DZbpZrMmSbbUZQ1WRQMePamniv")
    myHeaders.append("Content-Type", "application/json")

    var raw = JSON.stringify({
      subject: "Partner Program",
      html: `<h2>Informations</h2>
      <ul>
      <li>First name: ${firstName}</li>
      <li>Last name: ${lastName}</li>
      <li>E-mail: ${email}</li>
      <li>Company: ${company}</li>
      <li>Country: ${country}</li>
      <li>How did you find u-find: ${findUs}</li>
      <li>IT Partner: ${itPartner}</li>
      <li>Working with WiFi or IoT technology: ${wifiIot}</li>
      <li>Cloud Provider partner: ${cloud}</li>
      <li>Message: <h5>${message}</h5></li>
      </ul>
      `,
    })

    console.log(raw, "raw")

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }

    fetch(
      "https://europe-west2-ufind-prod.cloudfunctions.net/sendEmail?=",

      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        console.log(result)
        setSent("Thank you for your contact, your message has been sent.")
      })
      .catch(error => {
        console.log("error", error)
        setSent("Error sending message, please try again.")
      })
  }

  //   useEffect(() => {
  //     console.log(itPartner, "itPartner")
  //   }, [itPartner])

  return (
    <>
      <div className="container-fluid" id="requestDemoContainer">
        <Fade bottom>
          <div className="row">
            <div id="requestDemoTextArea">
              <h1>Early Stage Partner Program</h1>
              <p>
                Thanks for your interest in becoming u-find partner. Please fill
                in with maximum information. <br />
                we will read it carefully and get back to you as soon as
                possible.
              </p>
            </div>
            <div id="colRequestDemo">
              <div className="requestDemoFormDiv">
                <h4>Contact us</h4>
                <Form className="form" onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-lg" id="firstNameRow">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicFirstName"
                      >
                        <Form.Label className="formLabel">
                          First name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First name"
                          value={firstName}
                          onChange={handleFirstName}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg" id="lastNameRow">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicLastName"
                      >
                        <Form.Label className="formLabel">Last name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          value={lastName}
                          onChange={handleLastName}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg" id="emailRow">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="formLabel">E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="E-mail"
                          className="formInputs"
                          value={email}
                          onChange={handleEmail}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCompanyRole"
                      >
                        <Form.Label className="formLabel">Company</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Company"
                          className="formInputs"
                          value={company}
                          onChange={handleCompany}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg">
                      <Form.Group className="mb-3" controlId="formBasicCountry">
                        <Form.Label className="formLabel">Country</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Country"
                          className="formInputs"
                          value={country}
                          onChange={handleCountry}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg">
                      <Form.Group className="mb-3" controlId="formBasicCountry">
                        <Form.Label className="formLabel">
                          How did you find u-find?
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Social media / search Engine / others"
                          className="formInputs"
                          value={findUs}
                          onChange={handleFindUs}
                          //   required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg">
                      <Form.Group className="mb-3" controlId="formBasicCountry">
                        <Form.Label className="formLabel">
                          IT Partner (Y/N)
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          size="sm"
                          className="formSelectIT"
                          id="itPartnerPlaceholder"
                          value={itPartner}
                          onChange={onChangeItPartner}
                        >
                          <option style={{ fontSize: "13px !important" }}>
                            IT Partner (Y/N)
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-lg">
                      <Form.Group className="mb-3" controlId="formBasicCountry">
                        <Form.Label className="formLabel">
                          Working with WiFi or IoT technology?
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="WiFi or IoT technology"
                          className="formInputs"
                          value={wifiIot}
                          onChange={handleWifiIot}
                          //   required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg">
                      <Form.Group className="mb-3" controlId="formBasicCountry">
                        <Form.Label className="formLabel">
                          Cloud Provider partner?
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Google Cloud / Azure / AWS / other"
                          className="formInputs"
                          value={cloud}
                          onChange={handleCloud}
                          //   required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group
                    className="mb-3"
                    controlId="formBasicNumberOfEmployees"
                  >
                    <Form.Label className="formLabel">
                      Tell us more about you and why you want to be a u-find
                      partner.
                    </Form.Label>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Message"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ height: "100px" }}
                        value={message}
                        onChange={handleMessage}
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>

                  {/* ↓ Aparece esta mensagem no form quando enviamos com sucesso o email para a u-find */}
                  <p className="msgAppear">{sent}</p>
                  <div className="btnDiv">
                    <Button
                      variant="primary"
                      type="submit"
                      className="requestButton"
                      // href="https://docs.google.com/forms/d/e/1FAIpQLSc2iCNp01Z-757ljDEnMzFO-FaLpMhBrnYbOGa6SKaF83QTyQ/viewform"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default PartnerForm
