import React from "react"
import "./PartnerSolution.css"
import { IoMdCheckmarkCircle } from "react-icons/io"
import liveMap from "../../../images/website-optimized-images/live-map-3d 1.png"
import Fade from "react-reveal"

const PartnerSolution = () => {
  return (
    <>
      <div className="container-fluid" id="RetailSolutionMainDiv">
        <Fade bottom>
          <div className="PartnerSolutionTextArea">
            <h5 style={{ marginBottom: "1rem" }}>U-FIND PARTNER PROGRAM</h5>
            <br />
            <p>
              u-find solution is a SaaS solution made to be easy and quick to
              install anywhere in the world <br />
              It was made to be flexible and costumized to meet our customers
              needs.{" "}
            </p>
            <br />
            <p style={{ marginBottom: "0.7rem" }}>
              <b>If your are an IT company</b>
            </p>
            <p>
              u-find gives you a solution where you can add your own
              implementation services (map costumization, integration with
              Directory Services, WiFi optimization, etc.).
              <br />
              metrics and information that allows you to directly monetization
            </p>
            <br />
            <p style={{ marginBottom: "0.7rem" }}>
              <b>If technology is not your businesss,</b>
            </p>
            <p style={{ marginBottom: "3rem" }}>
              u-find provides all the services to successfully implement the
              solution for you anywhere. You manage the customer and the
              relationship, we take care of the rest!
            </p>
          </div>

          <div className="container" id="RetailSolution-container">
            <div className="row" id="rowRetailSolution1">
              <div
                className="col-lg order-2 order-lg-1"
                id="RetailSolutionImageDiv"
              >
                <img
                  src={liveMap}
                  className="RetailSolutionImage"
                  alt="ufind-RetailSolutionImage"
                />
              </div>
              <div
                className="col-lg order-1 order-lg-2"
                id="rowRetailSolution2"
              >
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  IT Integrators (ex: Cisco Meraki Partners)
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Real State operators
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Shoping Mall chain managers and/or solutions providers
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Industry key player and/or solution provider
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  IoT solution integrator
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconRetailSolution" />
                  Anyone willing to position our technology in the market
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default PartnerSolution
