import React from "react"
import "./PartnerHeroSection.css"
import laptop from "../../../images/website-optimized-images/laptop-mockup 3.png"
import partnerImage from "../../../images/ufind partners illustration.png"
import { Button } from "react-bootstrap"
import Fade from "react-reveal"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const PartnerHeroSection = () => {
  return (
    <>
      <div className="container" id="shopHeroContainer">
        <Fade bottom>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-lg-5" id="shopHeroTextArea">
              <h5>U-FIND PARTNER PROGRAM</h5>
              <h1>
                Want to join u-find Partner <br /> Program?
              </h1>
              <p>
                We are starting u-find partner Program (UPP). <br />
                Join us now and help to shape our program and the future of
                Smart Spaces,
                <br />
                Digital Twin buildings and many other new trends happening
                today. <br />
                u-find solution was build to be integrated in YOUR projects!{" "}
                <br />
                Join us and get a minimum of 20% out of u-find licensing
                customer price.
              </p>
              <div className="shopHero-btns">
                <Button
                  className="shopHeroButton"
                  variant="primary"
                  as={AnchorLink}
                  to="#requestDemoContainer"
                >
                  Contact us
                </Button>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="container" id="partnerImageDiv">
                <img
                  src={partnerImage}
                  className="shopHeroImage"
                  alt="partner illustration"
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default PartnerHeroSection
